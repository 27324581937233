<template>
  <span
    ><slot></slot>
    <b-sidebar
      sidebar-class="big_sidebar create-sefos-message"
      v-model="show"
      right
      :title="GetTitle"
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      @shown="shownSidebar"
      no-close-on-esc
      @hidden="closeBtn"
      v-if="SefosDTO"
    >
      <template #footer="{ hide }">
        <div v-show="step == 1" class="ml-2">
          <b-button 
          :disabled="saving"
          @click="closeBtn">{{ $t("CLOSE") }}</b-button>

          <b-button
            @click="saveMessage(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span><span v-if="saving">{{$t("SAVING")}}..</span></b-button
          >

          <b-button
            v-if="SefosDTO.response_to_message_uuid == ''"
            :disabled="disabledGotoStep2"
            @click="gotoStep2"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >

          <b-button
            v-if="
              SefosDTO.response_to_message_uuid != '' &&
              SefosDTO.external_participants.length != 0
            "
            :disabled="disabledGotoStep2"
            @click="gotoStep2"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >

          <b-button
            v-if="
              SefosDTO.response_to_message_uuid != '' &&
              SefosDTO.external_participants.length == 0
            "
            :disabled="disabledGotoStep2 && !AcceptAlotUsers"
            @click="saveMessage(false)"
            variant="primary"
            class="btn-fill"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            v-if="SefosDTO.message_uuid != ''"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
        <div v-show="step == 2" class="ml-2">
          <b-button @click="step = 1">{{ $t("BACK") }}</b-button>

          <b-button
            @click="saveMessage(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span><span v-if="saving">{{$t("SAVING")}}..</span></b-button
          >

          <b-button
            @click="gotoStep3"
            variant="primary"
            class="btn-fill"
            v-if="SefosDTO.external_participants.length != 0"
            >{{ $t("NEXT") }}</b-button
          >

          <b-button
            @click="gotoStep3"
            variant="primary"
            class="btn-fill"
            :disabled="!AcceptAlotUsers"
            v-if="SefosDTO.external_participants.length == 0"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            v-if="SefosDTO.message_uuid != ''"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
        <div v-show="step == 3" class="ml-2">
          <b-button @click="goBackStep2">{{ $t("BACK") }}</b-button>

          <b-button
            @click="saveMessage(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span><span v-if="saving">{{$t("SAVING")}}..</span></b-button
          >

          <b-button
            :disabled="disabledSendMessage"
            @click="saveMessage(false)"
            variant="primary"
            class="btn-fill"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            v-if="SefosDTO.message_uuid != ''"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
      </template>

      <b-overlay :show="!SefosDTO.isDraft && saving" rounded="sm">
        <div
          v-if="settings && loaded"
          class="view-content create-message px-3 py-2"
        >
          <div v-show="step == 1">

            <SelectFrom
              v-if="SefosDTO.response_to_message_uuid == ''"
              v-model="selected_from"
              :functionbox_uuid="SefosDTO.functionbox_uuid"
              :user_uuid="SefosDTO.user_uuid"
            ></SelectFrom>

            <div class="mt-2">{{ $t("ADD_RECIPIENTS") }}</div>

            <InputParticipants
              :key="'InputParticipants_' + externalParticipantsKey"
              :message_uuid="SefosDTO.message_uuid"
              :functionbox_uuid="SefosDTO.functionbox_uuid"
              :user_uuid="SefosDTO.user_uuid"
              message_type="message"
              :participants="SefosDTO.participants"
              :external_participants="SefosDTO.external_participants"
              @IsTyping="IsTyping"
              @UpdatedParticipants="UpdatedParticipants"
            ></InputParticipants>

            <div class="mt-2">{{ $t("INFORMATION") }}</div>

            <InputSubject
              v-model="SefosDTO.subject"
              :settings="settings"
              @valid="(n) => (validatedSubject = n)"
              :limit="256"
            ></InputSubject>

            <InputBody
              v-model="SefosDTO.body"
              :settings="settings"
              @valid="(n) => (validatedBody = n)"
              :limit="20000"
            ></InputBody>

            <div class="mt-2">{{ $t("ADD_FILES") }}</div>

            <div :key="'AddFiles' + SefosDTO.message_uuid">
              <InputFiles
                :message_uuid="SefosDTO.message_uuid"
                :functionbox_uuid="SefosDTO.functionbox_uuid"
                :user_uuid="SefosDTO.user_uuid"
                v-model="SefosDTO.attachments"
                :cannot-upload="$t('DRAFT.SEFOS_UPLOAD')"
                :max-size="200"
                @valid="(n) => (validatedFiles = n)"
              ></InputFiles>
            </div>
          </div>
          <div v-show="step == 2">
            <div class="mt-2">{{ $t("MESSAGES.LOA_LEVELS") }}</div>

            <InputLoa v-model="SefosDTO.settings.loa_level"></InputLoa>

            <div v-if="settings.reply">
              <div class="mt-2">{{ $t("RESPONSE") }}</div>

              <div class="ml-2">
                <b-form-checkbox
                  class="mt-2"
                  id="checkbox-1"
                  v-model="SefosDTO.settings.require_response"
                  name="require_response"
                  :value="1"
                  switch
                  :unchecked-value="0"
                >
                  {{ $t("MESSAGES.REQUEST_RESPONSE") }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div v-if="step == 3">
            <div class="mt-2">
              {{ $t("MESSAGES.EXTERNAL_RECIPIENTS") }}
            </div>

            <p>
              {{ $t("MESSAGES.NOT_REGISTERED") }}
            </p>

            <p>{{ $t("MESSAGES.NOT_REGISTERED_INFORMATION") }}</p>

            <div
            class="mt-2"
            :key="'NotRegistedLoa_' + SefosDTO.settings.loa_level + '_' + SefosDTO.external_participants"
          >
              <div
                v-for="(item, index) in SefosDTO.external_participants"
                :key="'NotRegistered_' + index"
                class="NotRegistered"
              >
                <NotRegistered
                  @change="UpdatedNotRegistered"
                  v-model="SefosDTO.external_participants[index]"
                  @remove="RemoveNotRegistered(index)"
                  message_type="message"
                  :user_uuid="SefosDTO.user_uuid"
                  :functionbox_uuid="SefosDTO.functionbox_uuid"
                  :methods="methods"
                  :loa_level="SefosDTO.settings.loa_level"
                ></NotRegistered>
              </div>
            </div>
            <b-button @click="step = 1" variant="secondary">{{
              $t("ADD_MORE_PARTICIPANTS")
            }}</b-button>

            <div class="mt-4">
              <i class="fa-light fa-lock-keyhole-open"></i>&nbsp;{{ $t("MESSAGES.EXTERNAL_TEXT") }}
            </div>

            <InputExternalText
              v-model="SefosDTO.external_text"
              :settings="settings"
              @valid="(n) => (validatedExternalText = n)"
              :limit="1500"
            ></InputExternalText>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    
    <b-modal
      id="show-accept-alot-users"
      v-model="ShowAcceptAlotUsers"
      hide-header
      @cancel="CancelAcceptAlotUsers"
      @ok="OkAcceptAlotUsers"
      centered
      :key="'AcceptAlotUsers' + (SefosDTO.external_participants.length.length + SefosDTO.participants.length.length)"
      :cancel-title="$t('NO')"
      :ok-title="$t('YES')"
    >
      {{ $t('SEND_ALOT_OF_USERS',  { amount: (SefosDTO.external_participants.length + SefosDTO.participants.length)  } ) }}
    </b-modal>
  </span>
</template>
<script>
import InputSubject from "../../Input/Subject";
import InputBody from "../../Input/Body";
import InputExternalText from "../../Input/ExternalText";
import InputParticipants from "../../Input/Participants";
import InputFiles from "../../Input/Files";
import NotRegistered from "../NotRegistered";
import InputLoa from "../../Input/Loa";
import SelectFrom from "../SelectFrom";
export default {
  components: {
    InputSubject,
    InputBody,
    InputExternalText,
    InputParticipants,
    NotRegistered,
    InputFiles,
    InputLoa,
    SelectFrom,
  },
  props: {
    functionbox_uuid: {
      default: "",
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    isReply: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isComplete: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isForward: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isReplyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    attachments: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      ShowAcceptAlotUsers: false,
      AcceptAlotUsers: true,
      externalParticipantsKey: 0,
      validatedSubject: true,
      validatedBody: true,
      validatedExternalText: true,
      isTyping: false,
      validatedFiles: true,
      loaded: false,
      saving: false,
      timeout: 2000,
      folder_uuid: "",
      canSave: false,
      changedContent: false,
      forceRedrawRecipientsIndex: 0,
      selected_from: {
        functionbox_uuid: this.functionbox_uuid,
        user_uuid: "",
      },
      SefosDTO: {
        user_uuid: this.user_uuid,
        functionbox_uuid: this.functionbox_uuid,
        action: "INIT",
        message_uuid: "",
        conversation_uuid: "",
        subject: "",
        body: "",
        attachments: [],
        participants: [],
        external_text: "",
        external_participants: [],
        settings: {
          loa_level: 3,
          open_to_date: null,
          open_from_date: null,
          require_response: 1,
        },
        custom_identifier: "",
        client: "web",
        response_to_message_uuid: this.message_uuid,
        webhook: "",
        isDraft: true,
      },
      step: 1,
      today: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      methods: [],
      loading: false,
      fields: [
        {
          thStyle: "width: 100%;",
          key: "authentication_identifier",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          thStyle: "width: 45%;",
          key: "email",
          class: "hidden-mobile",
          label: this.$t("EMAIL"),
          sortable: false,
        },
        {
          thStyle: "width: 45%;",
          key: "authentication_method",
          class: "hidden-mobile",
          label: this.$t("AUTH_METHODS.VERIFY"),
          sortable: false,
        },
        {
          thStyle: "width: 10%;",
          key: "language",
          class: "hidden-mobile",
          label: this.$t("LANGUAGE.TITLE"),
          sortable: false,
        },
      ],
      settings: null,
      timer: null,
    };
  },
  methods: {
    OkAcceptAlotUsers(){
      this.ShowAcceptAlotUsers = false;
      this.AcceptAlotUsers = true;
    },
    CancelAcceptAlotUsers(){
      this.ShowAcceptAlotUsers = false;
    },
    RemoveNotRegistered(index)
    {
      this.SefosDTO.external_participants.splice(index, 1);
      this.externalParticipantsKey++;
    },
    IsTyping(val) {
      this.isTyping = val;
    },
    UpdatedParticipants(updated) {
      this.SefosDTO.participants = updated.participants;
      this.SefosDTO.external_participants = updated.external_participants;
    },
    debounceSave() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage(true);
      }, this.timeout);
    },
    ChangedSelectedFrom() {
      this.SefosDTO.user_uuid = this.selected_from.user_uuid;
      this.SefosDTO.functionbox_uuid = this.selected_from.functionbox_uuid;
    },
    async shownSidebar() {
      await this.$store.dispatch("user/fetchUser");
      this.settings = await this.GetSettings();
      this.methods = await this.GetMethods();
      if (
        this.isReply ||
        this.isForward ||
        this.isReplyAll ||
        this.isComplete
      ) {
        await this.InitMessage();
      } else {
        if (this.message_uuid != "") {
          await this.GetInformation();
        } else {
          this.InitSettings();
        }
      }
    },
    InitDone() {
      this.loading = false;
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, this.timeout);
      this.SefosDTO.action = "NEW";
    },
    async InitMessage() {
      console.log("InitMessage");
      if (this.isReply) {
        this.SefosDTO.action = "REPLY";
      } else if (this.isReplyAll) {
        this.SefosDTO.action = "REPLYALL";
      } else if (this.isForward) {
        this.SefosDTO.action = "FORWARD";
      } else if (this.isComplete) {
        this.SefosDTO.action = "COMPLETE";
      }
      try {
        let result = await this.$http.post(
          this.user.hostname + "/sefos-message/init/secure",
          this.SefosDTO
        );
        this.SefosDTO = result.data;
        this.InitDone();
      } catch {
        console.log("Could not fetch InitMessage");
      }
    },
    async GetNewAttachments(message_uuid) {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: message_uuid,
            functionbox_uuid: this.SefosDTO.functionbox_uuid,
            user_uuid: this.SefosDTO.user_uuid,
          }
        );
        this.SefosDTO.attachments = result.data.sefosDTO.attachments;
      } catch {
        console.log("Could not fetch GetNewAttachments");
      }
    },
    async GetInformation() {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.SefosDTO.functionbox_uuid,
            user_uuid: this.SefosDTO.user_uuid,
          }
        );
        this.SefosDTO = result.data.sefosDTO;
        this.InitDone();
      } catch {
        console.log("Could not fetch GetInformation");
      }
    },
    async InitSettings() {
      this.SefosDTO.attachments = this.attachments;
      if (this.currentSignature != "") {
        this.SefosDTO.body =
          "\n\n----------------------------\n" + this.currentSignature;
      }
      if (this.settings.subject != "") {
        this.SefosDTO.subject = this.settings.subject;
      }
      if (this.settings.email_body != "") {
        this.SefosDTO.external_text = this.settings.email_body;
      }
      if (this.user.loa_default_level == 0) {
        if (this.user.loa_level_enabled.loa1_enabled) {
          this.SefosDTO.settings.loa_level = 1;
        }
        if (this.user.loa_level_enabled.loa2_enabled) {
          this.SefosDTO.settings.loa_level = 2;
        }
        if (this.user.loa_level_enabled.loa3_enabled) {
          this.SefosDTO.settings.loa_level = 3;
        }
      } else {
        this.SefosDTO.settings.loa_level = this.user.loa_default_level;
      }
      this.SefosDTO.settings.require_response = this.settings.reply_default ? 1 : 0;
      this.InitDone();
    },
    async closeBtn() {
      this.$emit("ChangeShow", false);
    },
    async RemoveDraft() {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/sefos-message/secure/remove", {
                message_uuid: self.SefosDTO.message_uuid,
                user_uuid: self.user_uuid,
                functionbox_uuid: self.functionbox_uuid,
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeBtn();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    async gotoStep2() {
      if (this.SefosDTO.response_to_message_uuid != "") {
        this.gotoStep3();
      } else {
        this.step = 2;
      }
    },
    async goBackStep2() {
      if (this.SefosDTO.response_to_message_uuid != "") {
        this.step = 1;
      } else {
        this.step = 2;
      }
    },
    async gotoStep3() {
      if (this.SefosDTO.external_participants.length == 0) {
        this.saveMessage(false);
      } else {
        this.step = 3;
      }
    },
    authenticationImage(authentication_method) {
      return "/img/auth/" + authentication_method + ".png";
    },
    async GetMethods() {
      let result = await this.$http.get(
        this.user.hostname + "/authenticate/external/methods"
      );
      return result.data;
    },
    UpdatedNotRegistered() {
      this.debounceSave();
    },
    ChangeLoaLevelNotRegistered(){

    },
    async saveMessage(isDraft) {
      if (this.saving || this.isTyping || this.disabledSave) {
        return;
      }
      this.saving = true;
      clearTimeout(this.timer);
      this.loading = true;
      this.SefosDTO.isDraft = isDraft;
      if (this.SefosDTO.external_text == "") {
        this.SefosDTO.external_text = this.$t(
          "MESSAGES.EXTERNAL_TEXT_PLACEHOLDER"
        );
      }
      try {
        let result = await this.$http.post(
          this.user.hostname + "/sefos-message/secure",
          this.SefosDTO
        );
        this.loading = false;
        if (isDraft) {
          this.saving = false;
          if (this.SefosDTO.message_uuid == "") {
            let checkNotUploaded =
              this.SefosDTO.attachments.filter(function (item) {
                return item.status == "NOT_UPLOADED";
              }).length != 0;
            if (this.attachments.length != 0 || checkNotUploaded) {
              await this.GetNewAttachments(result.data.message_uuid);
              this.SefosDTO.message_uuid = result.data.message_uuid;
            } else {
              this.SefosDTO.message_uuid = result.data.message_uuid;
            }
          }
        } else {
          this.SefosDTO.message_uuid = result.data.message_uuid;
          if (result.data.all_sent != undefined) {
            if (result.data.all_sent) {
              this.$noty.info(this.$t("SENT_MESSAGE"));
            } else {
              this.$noty.warning(this.$t("SENT_MESSAGE_NOT_SENT"));
            }
          } else {
            this.$noty.info(this.$t("SENT_MESSAGE"));
          }
          this.$emit("SentMessage");
          this.$emit("ChangeShow", false);
        }
      } catch (error) {
        this.saving = false;
        this.loading = false;
        clearTimeout(this.timer);
      }
    },
    async GetSettings() {
      this.loading = true;
      let result = await this.$http.post(this.user.hostname + "/settings/get", {
        setting: "SefosMessage",
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        functionbox_uuid: this.SefosDTO.functionbox_uuid,
      });
      return result.data;
    },
  },
  computed: {
    currentSignature() {
      let functionbox_uuid = this.functionbox_uuid;
      let user_uuid = this.user_uuid;
      let currentSignature = this.user.information.signature;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter(
          (c) => c.functionbox_uuid == functionbox_uuid
        );
        if (me.length != 0) {
          currentSignature = me[0].signature;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter(
            (c) => c.user_uuid == user_uuid
          );
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        }
      }
      return currentSignature;
    },
    disabledGotoStep2: function () {
      if (this.SefosDTO.subject == "") {
        return true;
      }
      if (this.SefosDTO.subject.length > 256) {
        return true;
      }
      if (this.SefosDTO.body.length > 20000) {
        return true;
      }
      if (this.SefosDTO.message_uuid == "") {
        return true;
      }
      if (
        this.SefosDTO.participants.length == 0 &&
        this.SefosDTO.external_participants.length == 0
      ) {
        return true;
      }
      if (!this.loaded) {
        return true;
      }
      if (this.saving) {
        return true;
      }
      if (!this.validatedFiles) {
        return true;
      }
      return false;
    },
    disabledSendMessage: function () {
      if(!this.AcceptAlotUsers){
        return true;
      }
      if (this.disabledGotoStep2) {
        return true;
      }
      let allValidated =
        this.SefosDTO.external_participants.filter(function (searchItem) {
          return searchItem.validated == true;
        }).length == this.SefosDTO.external_participants.length;
      if (!allValidated) {
        return true;
      }
      return false;
    },
    GetTitle() {
      if (this.saving) {
        if (this.SefosDTO.isDraft) {
          return (
            this.$t("MESSAGES.TYPE.message") +
            " (" +
            this.$t("SAVING") +
            " .. )"
          );
        } else {
          return (
            this.$t("MESSAGES.TYPE.message") +
            " (" +
            this.$t("SENDING") +
            " .. )"
          );
        }
      }
      return this.$t("MESSAGES.TYPE.message");
    },
    disabledSave() {
      return (
        !this.validatedBody ||
        !this.validatedExternalText ||
        !this.validatedSubject ||
        !this.validatedFiles
      );
    },
  },
  watch: {
    selected_from: function () {
      this.SefosDTO.user_uuid = this.selected_from.user_uuid;
      this.SefosDTO.functionbox_uuid = this.selected_from.functionbox_uuid;
      if (this.SefosDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "SefosDTO.body": function () {
      if (this.SefosDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "SefosDTO.participants": function (newVal, oldVal) {      
      if (this.SefosDTO.action != "INIT")
      {
        if((this.SefosDTO.external_participants + newVal.length) > (this.SefosDTO.external_participants + oldVal.length) && (this.SefosDTO.external_participants + newVal.length) > 19 )
        {
          this.AcceptAlotUsers = false;
          this.ShowAcceptAlotUsers = true;
        }
      }    
      if (this.SefosDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "SefosDTO.external_participants": function (newVal, oldVal) {      
      if (this.SefosDTO.action != "INIT")
      {
        if((this.SefosDTO.participants + newVal.length) > (this.SefosDTO.participants + oldVal.length) && (this.SefosDTO.participants + newVal.length) > 19 )
        {
          this.AcceptAlotUsers = false;
          this.ShowAcceptAlotUsers = true;
        }
      }      
      if (this.SefosDTO.action != "INIT" && this.canSave) {
        let allValidated = this.SefosDTO.external_participants.filter(function (
          searchItem
        ) {
          return (
            searchItem.validated == true ||
            searchItem.authentication_method == "NOT_SET"
          );
        });
        if (allValidated.length == this.SefosDTO.external_participants.length) {
          this.debounceSave();
        }
      }
    },
    "SefosDTO.external_text": function () {
      if (this.SefosDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "SefosDTO.settings.loa_level": function () {
      if (this.SefosDTO.action != "INIT" && this.canSave) {        
        this.debounceSave();
      }
    },
    "SefosDTO.subject": function () {
      if (this.SefosDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
  },
  mounted() {},
};
</script>