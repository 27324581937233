<template>
  <section
    v-if="info != null && info.email"
    :key="loa_level"
    v-bind:class="{ '': notSending }"
    v-show="info.authentication_method != 'donotsend'"
  >
    <div class="d-flex" :key="options.length">

      <div class="flex-grow-1">
        <div class="form-control-plaintext">
          <i class="fal fa-trash-alt clickable mr-2" @click="RemoveBtn"></i
          >
          <span v-b-tooltip.hover :title="GetName"><img class="icon mr-1" src="/img/message_type/message.png" v-if="showSefos" />{{ GetPartialName }}</span>
        </div>
      </div>
      <div>
        <b-form
          inline
          class="mb-2 w-350"
          v-if="info.authentication_method == 'conversation'"
        >
          <b-form-input trim
            class="w-350"
            plaintext
            :value="$t('CONVERSATION_CONFIGURED')"
          ></b-form-input>
        </b-form>
        <b-form
          inline
          class="mb-2"
          v-if="info.authentication_method != 'conversation'"
        >
          <b-form-select
            name="not_registered_select_language"
            v-model="info.language"
            class="choose-lang"
          >
            <b-form-select-option
              v-for="(value, key) in sefos_locales"
              :key="key"
              :value="key"
            >
              {{ $t("LANGUAGE." + key) }}
            </b-form-select-option>
          </b-form-select>
          <b-form-select
            class="w-250 no-mobile-padding-left"
            v-model="info.authentication_method"
            :options="options"
            :state="info.validated"
          ></b-form-select>
          <div class="mobile-block">
            <EmailOTP
              @valid="setValidated"
              required
              v-model="info.authentication_identifier"
              v-if="info.authentication_method == 'email-otp'"
            ></EmailOTP>
            <Phone
              @valid="setValidated"
              required
              v-model="info.authentication_identifier"
              v-if="info.authentication_method == 'sms'"
            ></Phone>
            <b-form-input trim
              v-if="hideIdentifier && info.authentication_method == 'se-eid'"
              type="password"
              value="HAS_VALUE"
              :state="true"
              @click="showIdentifier"
            ></b-form-input>
            <Pnr
              @valid="setValidated"
              required
              @blur="blurPnr"
              v-model="info.authentication_identifier"
              v-if="!hideIdentifier && info.authentication_method == 'se-eid'"
            ></Pnr>
          </div>
        </b-form>
      </div>
    </div>
  </section>
</template>
<script>
import Pnr from "../Input/Pnr.vue";
import Phone from "../Input/Phone.vue";
import EmailOTP from "../Input/EmailOTP.vue";
export default {
  components: { EmailOTP, Phone, Pnr },
  props: [
    "value",
    "index",
    "methods",
    "loa_level",
    "message_type",
    "functionbox_uuid",
    "user_uuid",
  ],
  data() {
    return {
      currentMethods: [],
      hideIdentifier: true,
      hasValue: false,
      info: this.value,
      languages: [],
      options: [],
      addressinfo: null,
      showSefos: false
    };
  },
  methods: {
    RemoveBtn(){
      this.$emit('remove');
    },
    showIdentifier(){
      if(this.hasValue)
      {
        this.info.authentication_identifier = "";
      /*}else{
        this.hideIdentifier = false;*/
      }
      this.hideIdentifier = false;
    },
    async ExactMatch(address) {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/auto-complete/exaktmatch/full",
          {
            message_type: this.message_type,
            search: address,
            emails: [],
            user_uuid: this.user_uuid,
            functionbox_uuid: this.functionbox_uuid,
          }
        );
        if(result.data == null)
        {
          return null;
        }
        if(result.data.item_type == null)
        {
          return null;
        }
        return result.data;
      } catch (error) {
        return null;
      }
    },
    async Init() {
      this.currentMethods = this.methods;
      if(this.info.authentication_method == 'conversation')
      {
        return;
      }
      try {
        let result = await this.$http.post(this.user.hostname + "/meetings/show-sefos", {
          email: this.info.email 
        });
        this.showSefos = result.data;
      } catch {
        this.showSefos = false;
      }
      if (this.showSefos) {
        this.options.push({
          value: "sefos-account",
          text: this.$t("AUTH_METHODS.sefos-account"),
        });
      }
      this.addressinfo = await this.ExactMatch(this.info.email);
      for (let ix = 0; ix < this.currentMethods.length; ix++) {
        let method = this.currentMethods[ix];
        if (method.loa_level >= this.loa_level) {
          if (method.auth_type == "link") {
            if (this.isPhone) {
              this.options.push({
                value: "link-sms",
                text: this.$t("AUTH_METHODS.sms"),
              });
            } else {
              this.options.push({
                value: "link",
                text: this.$t("AUTH_METHODS.link"),
              });
            }
          } else {
            if (method.auth_type == "sms") {
              if (this.isPhone) {
                this.options.push({
                  value: "email-otp",
                  text: this.$t("AUTH_METHODS.email-otp"),
                });
              } else {
                this.options.push({
                  value: "sms",
                  text: this.$t("AUTH_METHODS.sms"),
                });
              }
            } else {
              this.options.push({
                value: method.auth_type,
                text: this.$t("AUTH_METHODS." + method.auth_type),
              });
            }
          }
        }
      }
      if(this.info.authentication_method == "NOT_SET")
      {
        if(this.showSefos)
        {
          this.info.authentication_method = "sefos-account";
          this.ChangedAuthMethod("sefos-account");
        }else{
          if (this.loa_level == 1) {
            if(this.isPhone)
            {            
              this.info.authentication_method = "link-sms";
              this.ChangedAuthMethod("link-sms");
            }else{
              this.info.authentication_method = "link";
              this.ChangedAuthMethod("link");
            }        
          }
          if (this.loa_level == 2) {
            if(this.isPhone)
            {
              this.info.authentication_method = "email-otp";
              this.ChangedAuthMethod("email-otp");
            }else{
              this.info.authentication_method = "sms";
              this.ChangedAuthMethod("sms");
            }        
          }
          if (this.loa_level == 3) {
            let loa3Methods = this.currentMethods.filter(function (item) { return item.loa_level == 3 }).map((a) => a.auth_type);
            if(loa3Methods.includes("se-eid")){                      
              this.info.authentication_method = "se-eid";
              this.ChangedAuthMethod("se-eid");      
            }else{
              if(loa3Methods.includes("siths-card")){          
                this.info.authentication_method = "siths-card";
                this.ChangedAuthMethod("siths-card");      
              }
            }
          }
        }
      }else{
        if(this.info.authentication_identifier != "HAS_VALUE" && this.info.authentication_method == "se-eid")
        {
            this.hasValue = false;
            this.ChangedAuthMethod("se-eid");      
        }
        if(this.info.authentication_identifier == "HAS_VALUE" && this.info.authentication_method == "se-eid")
        {
            this.hasValue = true;
            this.ChangedAuthMethod("se-eid");  
        }
        if(this.showSefos)
        {
          this.info.authentication_method = "sefos-account";
          this.ChangedAuthMethod("sefos-account");
        }else{
          let loa3Methods = this.currentMethods.filter(function (item) { return item.loa_level == 3 }).map((a) => a.auth_type);
          let loa2Methods = this.currentMethods.filter(function (item) { return item.loa_level == 2 }).map((a) => a.auth_type);
          let loa1Methods = this.currentMethods.filter(function (item) { return item.loa_level == 1 }).map((a) => a.auth_type);
          switch(this.loa_level)
          {
            case 3:
              if(loa2Methods.includes(this.info.authentication_method) || loa1Methods.includes(this.info.authentication_method) || this.info.authentication_method == "email-otp" || this.info.authentication_method == "link-sms"){
                if(loa3Methods.includes("se-eid")){          
                  this.info.authentication_method = "se-eid";
                  this.info.authentication_identifier = "",
                  this.ChangedAuthMethod("se-eid");      
                }else{
                  if(loa3Methods.includes("siths-card")){          
                    this.info.authentication_method = "siths-card";
                    this.ChangedAuthMethod("siths-card");      
                  }
                }    
              }
              break;
            case 2:            
              if(loa1Methods.includes(this.info.authentication_method) || this.info.authentication_method == "link-sms"){
                if(this.isPhone)
                {
                  this.info.authentication_method = "email-otp";
                  this.info.authentication_identifier = "",
                  this.ChangedAuthMethod("email-otp");
                }else{
                  this.info.authentication_method = "sms";
                  this.info.authentication_identifier = "",
                  this.ChangedAuthMethod("sms");
                }
              }
              break;
            case 1:
              if(this.info.authentication_identifier == "" && this.info.authentication_method != "siths-card"){            
                if(this.isPhone)
                {
                  this.info.authentication_method = "link-sms";
                  this.info.authentication_identifier = "",
                  this.ChangedAuthMethod("link-sms");
                }else{
                  this.info.authentication_method = "link";
                  this.info.authentication_identifier = "",
                  this.ChangedAuthMethod("link");
                } 
              }
              break;
          } 
        }
      }
      this.CheckValidated(this.info.authentication_method);
    },
    Save(){
      this.$emit('input',this.info);
      this.$emit('changed');
    },
    blurPnr(){
      console.log("BANANA");
      if(this.hasValue && (this.info.authentication_identifier == "" || this.info.authentication_identifier == "HAS_VALUE"))
      {
        this.hideIdentifier = true;
        this.setValidated(true);
      }
    },
    setValidated(value) {
      this.info.validated = value;
      this.$emit("input", this.info);
    },
    checkIdentifier() {
      if(this.hasValue)
      {
        if (this.info.authentication_identifier == "") {
          this.hideIdentifier = true;
          this.info.authentication_identifier = "HAS_VALUE";
          this.setValidated(true);
        }
      }
    },
    CheckValidated(method)
    {
      if (method == "link") {
        this.setValidated(true);
      }
      if (method == "link-sms") {
        this.setValidated(true);
      }
      if (method == "email-otp") {
        this.setValidated(this.info.authentication_identifier == "" ? null : true);
      }
      if (method == "siths" || method == "siths-card") {
        this.setValidated(true);
      }
      if (method == "sefos-certificate") {
        this.setValidated(true);
      }
      if (method == "sefos-account") {
        this.setValidated(true);
      }
      this.Save();
    },
    GetIdentifier(authentication_method)
    {
      if (this.addressinfo == undefined) {
        return "";
      }
      if (this.addressinfo == null) {
        return "";
      }
      if (authentication_method == "sms") {
        return this.addressinfo.phone;
      }
      if (authentication_method == "email-otp") {
        return this.addressinfo.email;
      }
      if (authentication_method == "se-eid" || authentication_method == "bankid-se" || authentication_method == "freja") {
        if (this.addressinfo.identifier_format == "SE_EID" && this.addressinfo.identifier == "HAS_VALUE") {
          return "HAS_VALUE";
        }
      }
      return "";
    },
    ChangedAuthMethod(method)
    {
      let identifier = this.GetIdentifier(method);
      if (method == "sms" || method == "email-otp" || method == "se-eid" || method == "bankid-se" || method == "freja") 
      {
        if(this.info.authentication_identifier != identifier){
          this.info.authentication_identifier = identifier == null ? "" : identifier;
          this.Save();
        }
      }else{
        this.info.authentication_identifier = "";
      }
      if(this.info.authentication_identifier == "HAS_VALUE")
      {
        this.hasValue = true;
        this.hideIdentifier = true;
        this.setValidated(true);
      }else{
        this.hasValue = false;
        this.setValidated(null);
        this.showIdentifier();              
      }
      this.CheckValidated(method);
    },    
  },
  watch: {
    "info.authentication_method": function (newVal) {      
      this.ChangedAuthMethod(newVal);
    }
  },
  computed: {
    GetName(){
      if(this.addressinfo != null)
      {
        if(this.addressinfo.name != null)
        {
          if(this.addressinfo.name != "")
          {
            return this.addressinfo.name + " (" + this.info.email + ")";
          }
        }
      }
      return this.info.email;
    },
    GetPartialName()
    {
      return this.Truncate(this.GetName,40);
    },
    isEmail() {
      return this.validateEmail(this.info.email);
    },
    isPhone() {
      return this.validatePhone(this.info.email);
    },
    notSending() {
      return this.info.authentication_method == "donotsend";
    },
  },
  mounted() {
    this.Init();
  },
};
</script>
<style></style>